import { Icon } from '@iconify/react'
import { addUser } from 'API/user'

import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { useAppDispatch } from 'redux/hooks'
import { createGroup } from 'API/group'

import { ApplicationStateActions } from 'redux/slices/ApplicationState'

const AddGroupPage: React.FC = () => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [modalData, setModalData] = useState({
		name: '',
	})

	const dispatch = useAppDispatch()

	return (
		<>
			<div className='row mt-4 mx-2'>
				<div className='col-3'>
					<h2>Create Group</h2>
				</div>
				<div className='col-9 px-2'>
					{/* <div className='float-right'>
						<button onClick={() => window.open('/course_sample.csv')} className='btn btn-primary'>
							<Icon icon='bx:bx-download' className='fa fa-download px-2 '></Icon>
							Download Sample File
						</button>
					</div> */}
					<div className='float-right px-2'>
						<button className='btn btn-primary' onClick={handleShow}>
							<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
							<span className='pl-2'>Add Group</span>
						</button>
					</div>
					{/* <div className='float-right px-2'>
						<button
							className='btn btn-primary'
							onClick={() => {
								navigate('/admin/add/courses/batch')
							}}
						>
							<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
							<span className='pl-2'>Batch Upload</span>
						</button>
					</div> */}
				</div>
			</div>
			<>
				<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
					<form
						onSubmit={async (e) => {
							e.preventDefault()
							console.log(modalData)
							// await addUser(modalData)
							// handleClose()
							await createGroup(modalData.name)

							dispatch(ApplicationStateActions.setUpdate())
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Add Group</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Row></Row>

							<Row>
								<Col lg={12}>
									<Form.Group controlId='txtCourseName'>
										<Form.Label>Group Name</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Course Name'
											onChange={(e) => {
												setModalData({
													...modalData,
													name: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={12}>
									{/* <Form.Group controlId='txtDescription'>
										<Form.Label>Description</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Description'
											onChange={(e) => {
												setModalData({
													...modalData,
													description: e.target.value,
												})
											}}
										/>
									</Form.Group> */}
								</Col>

								<Col lg={6}>
									{/* <Form.Group controlId='txtEndDate'>
										<Form.Label>End Date</Form.Label>
										<input
											className='form-control'
											required
											type='datetime-local'
											placeholder='End Date'
											onChange={(e) => {
												setModalData({
													...modalData,
													end_date: e.target.value,
												})
											}}
										/>
									</Form.Group> */}
								</Col>

								<Col lg={6}>
									{/* <Form.Group controlId='txtCategory'>
										<Form.Label>Category</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Category'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													course_tag: e.target.value,
												}))
											}}
										/>
									</Form.Group> */}
								</Col>
							</Row>
						</Modal.Body>

						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Close
							</Button>
							<Button type={'submit'} variant='primary'>
								Save changes
							</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</>
		</>
	)
}
export default AddGroupPage
