import { listGroups } from 'API/group'
import { Link } from 'gatsby'
import { PromiseType } from 'utility-types'
import { getUserData } from 'API/user'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import AddGroupPage from '../add/group'
import Form from 'react-bootstrap/Form'
export default function Group() {
	const [groups, setGroups] = useState<string[]>()
	const [query, setquery] = useState<string>()
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	useEffect(() => {
		const run = async () => {
			const users = await listGroups()
			if (users) {
				setGroups(users.map((group) => group.name))
			}
		}

		run()
	}, [])
	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])
	return (
		<Fragment>
			{userData?.admin && <AddGroupPage />}
			<div style={{ margin: '0' }} className='col-6'>
				{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
				<Form.Control type='search' onChange={(e) => setquery(e.target.value)} placeholder='Search' className='me-2' aria-label='Search' />
			</div>
			<table className='table'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Group Name</th>
					</tr>
				</thead>
				<tbody>
					{groups
						?.filter((ele) => ele.includes(query || '')) // search
						?.map((elem, index) => {
							return (
								<>
									<tr>
										<td scope='row'>{index + 1}</td>
										{userData?.admin && (
											<Link to={`./${elem}`}>
												<td>{elem}</td>
											</Link>
										)}
										{userData?.manager && <td>{elem}</td>}
									</tr>
								</>
							)
						})}
				</tbody>
			</table>
		</Fragment>
	)
}
